.sun-editor .se-toolbar.se-toolbar-sticky {
	position: absolute;
}
.ant-rate {
	padding: 13px;
}
.toggle {
	/* display: none; */
}
.detail {
	cursor: pointer;
	font-size: 25px;
}
.diplaySelect {
	display: none;
}
.detail:focus {
	color: #da1717;
	background-color: red;
}
.requre_input {
	border-color: #ff1100;
}
.input_helper {
	/* padding:5px; */
}

.proizvostvo_pos {
	position: relative;
	display: flex;
	margin: 20px;
	left: 20px;
	bottom: -45px;
	width: max-content;
}
.required-start {
	color: red;
	font-size: 12px;
	font-weight: bold;
}
.specListsEditor {
	display: inline-flex;
}
.ant-steps-item-title {
	font-size: 11px;
	padding-right: 1px;
}
.input_new_agent {
	width: 65%;
	float: left;
	clear: left;
	/* padding:5px; */
	margin: 5px;
}
br {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

.treeview > .toggle {
	pointer-events: all;
	cursor: pointer;
}
.treeview > .toggle:hover {
	color: #63b1de;
	fill: #63b1de;
}
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	/* background: #373737; */
	/* overflow-y: auto; */
	font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 21px;
}
.ant-select-selection--multiple {
	width: 500px;
}
.toggle {
	width: 0px;
	background-color: aquamarine;
}
.card_agent_st {
	position: absolute;
	bottom: 90px;
}
.ant-tag {
	white-space: normal;
}
.tag_text {
	word-break: break-word;
}
.btn_pad {
	position: absolute; /* добавили */
	bottom: 70px; /* добавили */
}
.treeview > .toggle {
	pointer-events: all;
	cursor: pointer;
}
.treeview > .toggle:hover {
	color: #63b1de;
	fill: #63b1de;
}
.rusInputMap {
	flex-direction: column;
	/* display: flex; */
	align-items: center;
	justify-content: center;
}
.card-agent {
	width: 300px;
	height: 300px;
}
.agent_profile_btn {
	position: absolute;
}
.itg_icon {
	flex-direction: column;
	/* display: flex; */
	align-items: center;
	justify-content: center;
}
.phone_cotrolAgents {
	border-color: #c0e8e3;
	border-style: solid;
	background-color: tomato;
}
.dropdown_contr_agent {
	width: 200%;
	position: fixed;
}
.todo-cron-text {
	font-size: 150%;
	color: #f3f4f5;
}
.tod-cron-phone {
	background: #26ecec9d;
	padding: 5px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.tod-cron {
	padding: 5px;
	background: #04ffffe0;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.tod-cron:hover {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.1);
}
.ant-select-selection--multiple {
	width: 250px;
	max-width: 500px;
}
.Tags {
	width: 100%;
}
.editor_job_team_background {
	border-color: #9a9a9a;
	border-style: solid; /* Стиль границы */
	padding: 5px;
}
.fa-1x {
	font-size: 1.5rem;
}
.navbar-toggler.toggler-example {
	cursor: pointer;
}
.dark-blue-text {
	color: #0a38f5;
}
.dark-pink-text {
	color: #ac003a;
}
.dark-amber-text {
	color: #ff6f00;
}
.dark-teal-text {
	color: #2f413e;
}
.control_agent_main {
	position: absolute;
	padding: 1em 6em 0.1em;
	left: 50px;
}
.email_main_pos {
	position: absolute;
	padding: 1em 6em 0.1em;
	left: 50px;
}

.img_h3 {
	color: #fdeeee;
}
/* tabs:active */
.img_h3:target {
	color: #da1717;
}
.img_h3:active {
	color: #da1717;
}
.img_h3_copy {
	color: #fdeeee;
}
.img_h3_copy:active {
	color: #40e70ede;
}
.img_h3_delete {
	color: #fdeeee;
}
.img_h3_delete:active {
	color: #da1717;
}
.alert {
	position: absolute;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	top: 39em;
	border-radius: 0.25rem;
	width: 2000px;
	color: #000000;
	background-color: #d1ecf1;
}
.calendar_body {
	background-color: #ffffff;
}
.ant-icon-pos {
	float: left;
	padding: 1px;
}
.spec-border {
	padding: 5px;
	width: min-content;
}
.delete_ant_icon {
	float: left;
	margin: 5px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.postisitonRelativeSmeni {
		/* display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 6em 0.1em; */
	}
	.card-job-style {
		/* width: 55em */
	}
	.hr_job_list {
	}
	.new_jobs_list {
		max-width: 300px;
	}
	.profile_statistic_chart {
		display: none;
	}
	.fotter-bar-left {
		width: 0px;
		height: 0px;
	}
	.dSnone {
		display: none;
	}
	.edit_profile_main {
	}
	.todo-list-main-selector {
	}
	.container_button_job {
		margin-top: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.alert-yellow {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		background-color: #98e73175;
	}
	.company_worker_width {
	}
	.news_width {
		width: 100%;
	}
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
	.main_position_job_container {
		width: 55em;
	}
	.img-resize {
		/* width:100px; */
	}
}
@media only screen and (max-width: 480px) {
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
	.ComandTodoBorder {
		background: rgba(255, 255, 255, 0.513); /* Цвет фона */
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
		padding: 10px;
	}
	.SoloTodoBorder {
		background: rgba(240, 240, 240, 0.644); /* Цвет фона */
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
		padding: 10px;
	}
	.news_width {
		width: 100%;
	}
	.card-job-modile-style {
		width: 177px;
	}
	.img-resize {
		width: max-content;
	}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media screen and (min-width: 1024px) and (max-width: 1240px) {
}

@media only screen and (min-width: 600px) {
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
	.main_position_job_container {
		width: 55em;
	}
	.postisitonRelativeSmeni {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1em 6em 0.1em;
	}
	.text-editor-style {
		width: 100px;
		height: 100px;
	}
	.card-job-style {
		/* width: 55em */
	}
	.hr_job_list {
		width: 35em;
	}
	.new_jobs_list {
	}
	.profile_statistic_chart {
		width: 800px;
		height: 300px;
	}
	.fotter-bar-left {
		float: left;
		padding: 5px;
		background-color: #001529;
		/* border-radius: 5px; */
		border: thin rgba(0, 0, 0, 0.753) solid;
	}
	.edit_profile_main {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.todo-list-main-selector {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.alert-yellow {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		background-color: #98e73175;
	}
	.news_width {
		width: 100%;
	}
	.img-resize {
		width: 250px;
		height: 250px;
	}
	.ComandTodoBorder {
		background: rgba(255, 255, 255, 0.513); /* Цвет фона */
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
		padding: 10px;
		display: table-cell;
	}
	.SoloTodoBorder {
		background: rgba(240, 240, 240, 0.644); /* Цвет фона */
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */
		padding: 10px;
		display: table-cell;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.profile_statistic_chart {
		width: 800px;
		height: 300px;
	}
	.fotter-bar-left {
		float: left;
		padding: 5px;
		background-color: #001529;
		/* border-radius: 5px; */
		border: thin rgba(0, 0, 0, 0.753) solid;
	}
	.edit_profile_main {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.todo-list-main-selector {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.alert-yellow {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		background-color: #98e73175;
	}
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
	.news_width {
		width: 100%;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.postisitonRelativeSmeni {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
	.edit_profile_main {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.hr_job_list {
		width: 55em;
	}
	.new_jobs_list {
		width: 75em;
	}
	.profile_statistic_chart {
		width: 800px;
		height: 300px;
	}
	.news_width {
		width: 45em;
	}
	.fotter-bar-left {
		float: left;
		padding: 5px;
		background-color: #001529;
		/* border-radius: 5px; */
		border: thin rgba(0, 0, 0, 0.753) solid;
	}
	.todo-list-main-selector {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.alert-yellow {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		background-color: #98e73175;
	}
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
}
.positonEveryDayLent {
	left: -18em;
	top: 16em;
	/* bottom: 20em; */
	position: absolute;
	display: flex;
}
/* flex-row-reverse */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.hr_job_list {
		width: 55em;
	}
	.postisitonRelativeSmeni {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.profile_statistic_chart {
		width: 800px;
		height: 300px;
	}
	.fotter-bar-left {
		float: left;
		padding: 5px;
		background-color: #001529;
		/* border-radius: 5px; */
		border: thin rgba(0, 0, 0, 0.753) solid;
	}
	.edit_profile_main {
		left: 9em;
		top: 2em;
		/* bottom: 20em; */
		position: absolute;
		display: flex;
	}
	.alert-yellow {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		background-color: #98e73175;
	}
	.news_width {
		width: 75em;
	}
	.alert-complete-status {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
		color: #000000;
		background-color: #09caecb7;
	}
}
/* 
@media screen and (min-width: 500px) {
  .postisitonRelativeSmeni {
    left: 9em;
    top: 2em;
    /* bottom: 20em; */
/* position: absolute;
    display: flex;
  }
  .news_width{
    width: 35em;
  }
  .alert-complete-status {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    color: #000000;
    background-color: #09caecb7;
  }
} */
*/ .alert-warning-yellow {
	color: #00000094;
	background-color: #98e73175;
	/* border-color: #0f0b0fc7; */
}
.fade.show-yellow {
	opacity: 1;
}
.alert-dismissible-yellow {
}
/* .alert-yellow {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 100vh;
} */
.Link {
	color: #000000;
}
.tabel {
	padding: 1000px;
}
.css-o2cm99 {
	background-color: rgb(235, 236, 240);
	display: flex;
	flex-direction: column;
	opacity: inherit;
	user-select: none;
	width: 250px;
	padding: 8px 8px 0px;
	border-width: 8px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
}
.part2 {
	left: 25em;
	top: 0em;
	bottom: 20em;
	position: absolute;
	display: flex;
}
.input {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #0b67c2;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal-list-search {
	padding: 5px;
}

.modal-dialog {
	top: -15em;
}
.modal-content-position {
	/* left: 10px;
    right: 12px; */
	width: 100%;
	position: absolute;
	top: 25em;
	/*TODO: Hack  css   */
}
.search_positon {
	left: 35em;
	position: absolute;
}
.square-red {
	width: 1em;
	height: 1em;
	background: red;
}
.square-yellow {
	width: 1em;
	height: 1em;
	background: yellow;
}
.job-main-content {
	position: relative;
	padding: 3em 0em 0em 3em;
	left: 50px;
	width: 100%;
}
.todo-yellow {
	width: 190px;

	padding: 5px;
	background: #fff30fc0;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	/* box-shadow: inset 5em 1em gold; */
}
.todo-red {
	width: 190px;

	padding: 5px;
	background: #f72d2dc9;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

	/* box-shadow: inset 5em 1em #f24141; */
}
.todo-comand-yellow {
	padding: 5px;
	background: #fff30fc0;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	box-shadow: inset 5em 1em gold;
}
.todo-comand-red {
	padding: 5px;
	background: #f72d2dc9;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

	box-shadow: inset 1em 0em rgb(219, 23, 17);
}
/* :hover */
.deal-purpur {
	padding: 5px;
	background: #996bd5;
	max-width: 100%;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

	/* box-shadow: inset 5em 1em #f24141; */
}
/* :hover */
.deal-purpurs:hover {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.1);
}
.todo-red:hover {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.1);
}
.todo-green:hover {
	-webkit-transform: rotateZ(20deg);
	-ms-transform: rotateZ(20deg);
	transform: scale(1.1);
}
.todo-yellow:hover {
	/* -webkit-transform: rotateZ(10deg); */
	/* -ms-transform: rotateZ(10deg); */
	transform: scale(1.1);
}
.todo-green {
	width: 190px;
	padding: 5px;
	background: #15b11ac4;
	max-width: 100%;
	/* box-shadow: inset 5em 1em #50ba29; */
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.todo-phone-red {
	background: #ec26269d;
	padding: 5px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.todo-phone-yellow {
	background: #fff30f86;
	padding: 5px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.todo-phone-green {
	background: #8ed61398;
	padding: 5px;
}
.deal-phone-green {
	background: #7b4f7ddc;
	padding: 5px;
}
.square-green {
	width: 1em;
	height: 1em;
	background: green;
}
.square-return {
	width: 1em;
	height: 1em;
	background: white;
}
.agent-list {
	border-radius: 25px;
	background: #73ad21;
	padding: 20px;
	width: 200px;
	height: 150px;
}
.layer-list-agent {
	/* overflow-y: scroll;
    width: 300px; 
    height: 100em;
    padding: 5px;  */
	/* overflow: auto; */

	max-height: 100em;
	margin: 30px auto;
	background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}
.positionAgentLits {
	display: flex;
	align-items: center;
	justify-content: center;
}
.square-red-sort {
	width: 3em;
	height: 3em;
	background: red;
	border: none;
}
.square-return-sort {
	width: 3em;
	height: 3em;
	background: white;
	border: none;
}
.square-green-sort {
	width: 3em;
	height: 3em;
	background: green;
	border: none;
}
.square-yellow-sort {
	width: 3em;
	height: 3em;
	border: none;
	background: yellow;
}
.positionLeft {
	width: 8em;
	max-width: 100%;
	min-height: 2em;
	margin: 0 0 0 auto; /* то же, что margin-left: auto;  */
	border: 1px solid orange;
	background: #fff5d7;
}
.ant-back-top {
	bottom: 100px;
}
.ant-back-top-inner {
	height: 40px;
	width: 40px;
	line-height: 40px;
	border-radius: 4px;
	background-color: #1088e9;
	color: #fff;
	text-align: center;
	font-size: 20px;
}
.icon-work {
	height: 10em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.TodoListBorder {
	/* background: #f6efef;  */
	/* border: 1px solid rgb(8, 134, 218);  */
	padding: 1em;
	margin-bottom: 1.5em;
}
.TodoListOneJob {
	/* background: #ffc422;  */
	border: 0.3em solid #002140;
	border-radius: 8px;
	padding: 1em;
	margin-bottom: 1.5em;
}
.TextDealHistory {
	text-align: center;
}
.ant-card-bordered {
	border: none;
}
.todo-text-status-job {
	background-color: #064f8b36;
}
.todo-text-status-green {
	background-color: #20ac9977;
}
.mainMenu {
	position: fixed;
}
.alert-alert-info {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	/* width: 100vh; */
	color: #000000;
	background-color: #6905ec8e;
}

.news-width {
	/* max-width:  auto; */
	width: 79vmin;
}
.alert-new-comment {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	/* width: 100vh; */
	color: #000000;
	background-color: #ffbc057e;
}

.alert-redirect-todo {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	color: #000000;
	background-color: #ec09a0b7;
}
.security {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	color: #000000;
	background-color: #c22323b0;
}
.shadow-test {
	padding: 5px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.markdown-input_fullscreen {
	overflow: hidden;
}
.markdown-input_fullscreen.modal.in .modal-dialog {
	transform: none;
}
.shadowUserList {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.ButtonPosition {
}
.strokeEditDevice {
	color: #fff;
	padding: 10px;
	background-color: #108ee9;
	-webkit-box-shadow: 14px 17px 52px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 14px 17px 52px -6px rgba(0, 0, 0, 0.75);
	box-shadow: 14px 17px 52px -6px rgba(0, 0, 0, 0.75);
}
.IconMobile {
	align-items: center;
	justify-content: center;
}
.Mobile_menu_row {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em 6em 0.1em;
}
.russian-map-wrap {
	display: absolute;
	padding: 50px;
}
.gallery {
	width: 300px;
	height: 200px;
	position: relative;
	padding: 0;
	margin: 0;
	text-align: center;
}

.gallery-image {
	cursor: pointer;
	position: relative;
	display: block;
}

.gallery-text {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 300px;
	height: 200px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	transition: opacity 0.6s;
	vertical-align: middle;
	line-height: 200px;
	cursor: pointer;
}

.gallery-text:hover {
	opacity: 1;
}

.gallery-text h3 {
	display: inline-table;
	vertical-align: middle;
	line-height: 100%;
	/* color: rgb(219, 23, 17); */
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.btn-uploaded {
	border: 2px solid gray;
	color: gray;
	background-color: #ffffff;
	padding: 8px 20px;
	border-radius: 8px;
	font-size: 20px;
	font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
#footer {
	bottom: 0; /* Левый нижний угол */
	width: 100%; /* Ширина слоя */
}
.avatar {
	float: right;
	font-size: smaller;
	text-indent: 0;
	margin: 0.5em;
	padding: 0.5em;
	background-color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
		Segoe UI Emoji;
	font-size: 14px;
	line-height: 1.5;
	color: #24292e;
	max-width: 300px;
}
.todo-yellow-text {
	font-size: 150%;
	color: #24292e;
}
.todo-green-text {
	font-size: 150%;
	color: #24292e;
}
.todo-red-text {
	font-size: 150%;
	color: #f3f4f5;
}
.fotter-bar-left {
	float: left;
	padding: 5px;
	background-color: #001529;
	/* border-radius: 5px; */
	border: thin rgba(0, 0, 0, 0.753) solid;
}
code {
	color: #fff;
}
.user-online {
	width: 10px;
	height: 10px;
	border: 2px solid rgb(142, 250, 19);
	border-radius: 50%;
	background: rgb(142, 250, 19);
}
.user-ofline {
	width: 10px;
	height: 10px;
	border: 2px solid red;
	border-radius: 50%;
	background: red;
}
.message-list {
	background-color: #fff;
}
.main-assigned-todo-list {
}

/* a {
  text-decoration: none;
  color: #1E1E23;
  opacity:1;
  font-family: 'work sans', sans serif;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
}
a:hover {
  opacity:0.5;
} */
ul {
	padding: 0;
	list-style-type: none;
}

.container {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.phone {
	background-color: #36383f;
	border-radius: 40px;
	width: 300px;
	height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 30px 50px 100px #85888c;
}
.content {
	border: 1px solid #36383f;
	border-radius: 20px;
	width: 94%;
	height: 91%;
	background-color: #f5f6fa;
	overflow: hidden;
}
nav {
	background-color: #1e1e23;
	height: 65px;
	display: flex;
	position: relative;
}

#menuToggle {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 25px;
	left: 25px;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
}

#menuToggle input {
	display: flex;
	width: 40px;
	height: 32px;
	position: absolute;
	cursor: pointer;
	opacity: 0;
	z-index: 2;
}

#menuToggle span {
	display: flex;
	width: 29px;
	height: 2px;
	margin-bottom: 5px;
	position: relative;
	background: #ffffff;
	border-radius: 3px;
	z-index: 1;
	transform-origin: 5px 0px;
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
		opacity 0.55s ease;
}

#menuToggle span:first-child {
	transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
	transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
	opacity: 1;
	transform: rotate(45deg) translate(-3px, -1px);
	background: #36383f;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
	transform: rotate(-45deg) translate(0, -1px);
}

#menu {
	position: absolute;
	width: 25em;
	height: 50em;
	box-shadow: 0 0 10px #85888c;
	margin: -50px 0 0 -50px;
	padding: 50px;
	padding-top: 125px;
	background-color: #f5f6fa;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(-100%, 0);
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
	padding: 10px 0;
	transition-delay: 2s;
}

#menuToggle input:checked ~ ul {
	transform: none;
}
.ant-badge-count {
	z-index: 0;
}
.ant-tabs-tab-active {
	z-index: 0;
}
.sun-editor {
	z-index: 0;
}
.sun-editor {
	z-index: 0;
}
.sun-editor .se-wrapper {
	z-index: 0;
}
.sun-editor .se-toolbar {
	z-index: 0;
}
.ant-tabs {
	z-index: 0;
}
.profile_statistic {
}
.ql-editor {
	background-color: #fff;
}
.menu_frame {
	/* border: 4px double black;
  background: #fc3;
  padding: 10px; */
}
.user-list {
	left: 20px;
}
.editor_job_lonely {
	/* left:20px; */
}
.name_user {
	word-break: break-word;
	max-width: 300px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	width: 300px;
}
.container-bootstrap {
	width: 100%;
	padding-right: 15px;
	/* padding-left: 15px; */
	margin-right: auto;
	/* margin-left: auto; */
}
.content {
	/* margin-top: 50px; */
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.editor_regim {
	position: relative;
	top: 63px;
	display: table-caption;
}
.editor_list {
	display: inline-flex;
	padding: 2px;
}

.container-job {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 40px;
}
@media (min-width: 1200px) {
	.container-job {
		max-width: 1140px;
	}
}

@media (min-width: 992px) {
	.container-job {
		max-width: 960px;
	}
}
@media (min-width: 768px) {
	.container-job {
		max-width: 720px;
	}
}
@media (min-width: 576px) {
	.container-job {
		max-width: 540px;
	}
}

.ant-steps-item-title {
	padding-right: 19px;
}
.bg-item-spec-job {
	background: whitesmoke;
	border: 3px solid #002140;
}
.img-icon {
	width: 85px;
	position: relative;
}

.img-icon h5 {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
	height: 100px;
}
#root {
	overflow: hidden;
	overflow-y: auto;
}

::-webkit-scrollbar {
	width: 12px;
}
::-webkit-scrollbar-thumb {
	border-width: 1px 1px 1px 2px;
	border-color: rgba(68, 22, 196, 0.534);
	background-color: rgb(170, 170, 170);
}
::-webkit-scrollbar-thumb:hover {
	border-width: 1px 1px 1px 2px;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	-webkit-animation: gradientBG 10s ease infinite;
	animation: gradientBG 10s ease infinite;
}
::-webkit-scrollbar-track {
	border-width: 0;
}
::-webkit-scrollbar-track:hover {
	border-left: solid 1px #aaa;
	background-color: #eee;
}
@-webkit-keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.agentChart {
	width: 550px;
	height: 550px;
}
.agent-profile-info {
}
.agent-profile-info div {
	font-size: 20px;
	color: black;
}
.activiti-list {
	overflow-y: auto;
	width: 50vh;
	height: 387px;
}

.activiti-list::-webkit-scrollbar {
	width: 12px;
}
.activiti-list::-webkit-scrollbar-thumb:hover {
	border-width: 1px 1px 1px 2px;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	-webkit-animation: gradientBG 10s ease infinite;
	animation: gradientBG 10s ease infinite;
}
.agentnew_front {
	font-size: 15px;
	color: #000000;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-bottom: 13px;
}
.comment-and-todo-list {
	/* background-color: #cac7c78c; */
	border-radius: 4px;
}
.comment-agent-list {
	background-color: #1982e4cc;
	color: white;
	margin: 4px;
	font-size: 17px;
}
.ant-timeline-item-head {
	background-color: #f0f2f5;
}
.news_pos {
	margin-left: 102px;
}
.margin__menu {
	margin-left: 90px;
}
.newCommentNews {
	box-shadow: 0 0 10px rgba(59, 153, 31, 0.5);
	padding: 8px;
	background-color: #44bc50c9;
	border-radius: 8px;
}
.NewJobAtNews {
	box-shadow: 0 0 10px rgba(59, 153, 31, 0.5);
	padding: 8px;
	background-color: #44bc50;
	border-radius: 8px;
}
.has_come_todo {
	/* box-shadow: 0 0 10px rgba(217, 0, 255, 0.5); */
	padding: 8px;
	/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(238, 65, 127, 0.69) 0%, rgba(189, 129, 216, 0.54) 23%, rgba(183, 138, 212, 0.58) 34%, rgba(212,172,226,1) 53%, rgba(230, 55, 223, 0.61) 89%); */
	border-radius: 8px;
	background-image: -moz-linear-gradient(
		center bottom,
		rgba(2, 0, 36, 1) 0%,
		rgba(238, 65, 127, 0.69) 0%,
		rgba(189, 129, 216, 0.54) 23%,
		rgba(183, 138, 212, 0.58) 34%,
		rgba(212, 172, 226, 1) 53%,
		rgba(230, 55, 223, 0.61) 89%
	);
}
.New_agent_news {
	box-shadow: 0 0 10px rgba(19, 137, 167, 0.5);
	padding: 8px;
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(65, 237, 238, 1) 0%,
		rgba(129, 216, 202, 1) 23%,
		rgba(138, 212, 176, 1) 34%,
		rgba(172, 226, 218, 1) 53%,
		rgba(55, 164, 230, 1) 89%
	);
	border-radius: 8px;
}

.comnd-Todo {
	box-shadow: 0 0 10px rgba(19, 167, 56, 0.5);
	padding: 8px;
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(106, 238, 65, 1) 0%,
		rgba(175, 216, 129, 1) 32%,
		rgba(135, 215, 162, 1) 53%,
		rgba(226, 230, 55, 1) 89%
	);
	border-radius: 8px;
}
.solo-Todo {
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(65, 223, 238, 0.49) 0%,
		rgba(129, 154, 216, 1) 32%,
		rgba(135, 157, 215, 1) 53%,
		rgba(55, 113, 230, 0.68) 89%
	);
	padding: 8px;
	/* background-color: #d14115; */
	border-radius: 8px;
}
.br8px {
}
.px100 {
	width: 100%;
	background-color: white;
	background: #ffffff;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	/* padding: 10px; */
}
.pos-icon {
	margin-left: 5px;
	margin-right: 4px;
	margin-top: 7px;
}
/*     left: -10px; */
.ant-avatar.ant-avatar-icon {
	left: -10px;
}
.ant-avatar {
	left: -13px;
}
.leftpos {
	display: flex;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	background-color: #ffffff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
	/* width: 40px; */
}
.close {
	margin-bottom: auto;
	color: black;
	radius: 50%;
	border-radius: 50%;
	width: 22px;
	font-size: 15px;
	left: -23px;
	position: relative;
	top: 9px;
}
/* 598 */
@media (max-width: 600px) {
	.close {
		display: none;
	}
}
.news div {
	color: #000000;
}
.user-complete {
	color: #1088e9;
	font-size: 16px;
}
.todo-news-titel {
	color: #1088e9;
	font-size: 16px;
}
.todo-main {
	margin-left: 100px;
}
/* 665 */
@media (max-width: 665px) {
	.todo-main {
		margin-left: 0px;
	}
}
.list-agent-at-manager {
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
}
.active {
	margin-left: 16px;
}
.border-right {
	border-right: 2px #0f0f0fab solid;
}
.peopel-at-agent {
	display: flex;
	justify-content: start;
	flex-direction: column;
}
.avatar-img {
	list-style: none;
	position: relative;
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	width: 32px;
	height: 32px;
	line-height: 32px;
	margin: 3px;
	border-radius: 50%;
}
.message-list {
	position: fixed;
	margin-left: 20vh;
}
.search_helper {
	display: inline-flex;
	margin-left: 1vw;
	margin-top: 1vh;
}
.human_pos {
	margin-left: 100px;
}
@media (max-width: 665px) {
	.human_pos {
		margin-left: 0px;
	}
}
.editHuman {
	color: #cf5f79;
}
.editHumDefault {
	color: #1933a76c;
}
.border-new-agent {
	border: 1px solid #2196f3;
	display: grid;
}
.requre_input {
	color: red;
}
.centeredInline {
	display: inline-flex;
	justify-items: center;
	align-items: center;
}
@media(max-width: 665px){
	.email_main_pos{
		position: relative;
		padding: 0px;
		left: 20px;
		margin-top: 10px;
	}
}
.boootstrap_helperColumn{
	flex-direction: column;
}

.drawer_flex{
	display: flex;
}
.select_helper_render{
	width: 250px;
	/* max-width: 500px; */
}
.input_new_agent_helper{
	width: 65%;
float: left;
clear: left;

margin-top: 5px;
margin-bottom: 5px;
}
.flex{
	display:flex;
}

.flex > div{
	margin-left: 10px;
}
.flex > span{
	margin-left: 10px;
}
.SelectHelper{
	color:red
}
.ant-select-selection__placeholder{

}
.flex-rev{
	display: flex;
	flex-direction: row-reverse;
}
.mr5px{
	margin: 5px;
}
.edit_conter_agent_profile{
	border-left-color: #f31a1e;
    border-left-style: dashed;
    border-left-width: 3px;
    padding: 0 10px;
}
.INptAng{
	width: 50%;
	left:auto;
    margin-left: auto;
}

.mr5px{
	margin-right: 3px;
}
.width1{
	width: 100%;
}
.btn_save{
	position: fixed;
    top: 50%;
    right: 10px;
}

.fuck_btn{
	margin-right: 4px;
    margin-left: 4px;
    width: 56px;
}

.centerd_fuck{
	display: flex;
    align-items: center;
}